import ContainerBase from 'components/PageContainer';
import styled from 'styled-components';

export const Container = styled(ContainerBase)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    margin-top: 70px;
    background: #fff;
    padding: 15px 15px 15px 60px;
  }
  padding: 45px 15px 15px 15px;
`;

export const Title = styled.h1`
  color: rgb(56, 64, 77);
  margin: 1.75rem 0 0.875rem;
  font-weight: 700;
  font-family: Poppins, serif;
`;

export const Main = styled.div`
  background: #ffffff;
  position: relative;
  z-index: 3;
`;
