import styled from 'styled-components';

export const SubmitButton = styled.div`
  margin-top: 30px;
  display: inline-flex;
  width: 100%;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    justify-content: flex-end;
  }
`;
