import styled from 'styled-components';

export const Button = styled.button`
  height: 18px;
  width: 18px;
  cursor: pointer;
  border: 0;
  background: none;
  padding: 0;
`;

export const Dot = styled.div<{ active: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin: 3px;
  ${({ active }) =>
    active
      ? `
      background-color: #c1c1c1;
    `
      : `
      background-color: #efefef;
    `}
`;
