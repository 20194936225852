import React, { FunctionComponent, ReactElement } from 'react';

// Styles
import { Container } from './style';

interface Props {
  message: string | ReactElement;
}

const Error: FunctionComponent<Props> = ({ message, ...rest }) => {
  return (
    <Container role="alert" {...rest}>
      {message}
    </Container>
  );
};

export default Error;
