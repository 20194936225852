import { Card as CardBase, CardHeader, Typography } from '@material-ui/core';
import { primaryColor } from 'assets/theme/colors';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import styled from 'styled-components';

export const Card = styled(CardBase)`
  margin-bottom: 50px;
`;

export const HeaderTitle = styled(CardHeader)`
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const Title = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 15px;
    padding-left: 11px;
    padding-top: 10px;
  }
  font-size: 15px;
  padding-left: 2px;
  padding-top: 5px;
`;

export const SectionTitle = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  padding-left: 11px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    border-top: 1px solid #d5d5d5;
    font-size: 18px;
    font-weight: bold;
    padding: 19px 0 0 11px;
  }
`;

export const Text = styled(Typography)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: 20px;
    padding-left: 11px;
  }
`;

export const CardFooter = styled.div`
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: ${primaryColor};
  color: #ffffff;
`;

export const CardFooterText = styled(Typography)`
  font-size: 20px;
`;

export const GridContainerTop = styled(GridContainer)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-bottom: 1px solid #d5d5d5;
    padding-bottom: 20px;
  }
  padding-bottom: 20px;
`;

export const GridItemLeft = styled(GridItem)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    border-right: 1px solid #d5d5d5;
    padding-top: 30px;
  }
  border-right: none;
`;

export const GridItemRight = styled(GridItem)`
  padding-top: 20px;
`;
