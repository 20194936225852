import { FormControlLabel as FormControlLabelBase, Paper, Typography } from '@material-ui/core';
import { primaryColor } from 'assets/theme/colors';
import styled from 'styled-components';

export const Text = styled(Typography)`
  font-size: 15px;
`;

export const BackgroundInfo = styled(Paper)`
  background: rgba(255, 253, 108, 0.3);
  height: 70px;
  margin-top: 20px;
`;

export const FormControlLabel = styled(FormControlLabelBase)`
  width: 100%;
  height: 100%;
  margin: 0px;
  padding-left: 12px;
`;

export const ConditionsLink = styled.a`
  color: ${primaryColor};
  &:hover {
    text-decoration: underline;
  }
`;

export const ButtonPaper = styled(Paper)`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;
