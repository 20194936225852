import { Typography } from '@material-ui/core';
import styled from 'styled-components';

export const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 40px;
`;

export const Image = styled.img`
  float: left;
  width: 400px;
  clear: left;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const Text = styled(Typography)`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
`;
