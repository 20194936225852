// @material-ui/core components
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { FunctionComponent } from 'react';

// Style
import { Backdrop } from './style';

const IziCardCustom: FunctionComponent = () => (
  <Backdrop open={true}>
    <CircularProgress />
  </Backdrop>
);

export default IziCardCustom;
