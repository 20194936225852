// @material-ui/core components
import {
  Divider,
  FormControl,
  FormControlLabel,
  List,
  ListItem,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { Control, Controller } from 'react-hook-form';
// Style
import { ParkApplicationValues } from 'types/parkApplication';

import { BackgroundInfo, Intro, ListItemText, Subtitle, Text } from './style';

interface Props {
  data: ParkApplicationValues;
  control: Control;
}

const Terms: FunctionComponent<Props> = ({ control }) => {
  const date = () => {
    const day = new Date().getDate();
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();
    if (month < 10) {
      return `${day}/0${month}/${year}`;
    }
    return `${day}/${month}/${year}`;
  };

  return (
    <>
      <Intro>Sont convenues les conditions particulières de partenariat suivantes :</Intro>
      <List>
        <ListItem>
          <ListItemText primary="1. Objet" />
        </ListItem>
        <Text>
          Dans le cadre du présent contrat, le Prestataire assurera pour le compte du Partenaire la
          commercialisation et la promotion de ses séjours et prestations via la plateforme internet
          Izicamp.fr et ses autres canaux de diffusion et de communication.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="2. Contrat de partenariat" />
        </ListItem>
        <Text>
          Le présent contrat est formé des présentes conditions particulières et des conditions
          générales du partenariat. Le tout formant un ensemble indivisible.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="3. Durée du contrat" />
        </ListItem>
        <Text>
          Sauf mention contraire, le présent Accord prend effet à la date de sa confirmation par le
          Prestataire pour une durée indéterminée. Toute Partie peut mettre fin à l&apos;Accord, à
          tout moment et pour n&apos;importe quel motif, dans les conditions indiquées au paragraphe
          5 des conditions générales du présent accord de partenariat.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="4. Conditions financières" />
        </ListItem>
        <Subtitle>Pourcentage de commission de vente de nuitées : </Subtitle>
        <Text>
          En contrepartie de la commercialisation des séjours du partenaire par izicamp.fr, le
          Prestataire percevra une commission qui s’élève à 17%HT du montant TTC pour chaque nuitée
          vendue.
        </Text>
        <br></br>
        <Subtitle>
          Pourcentage de commission de services et prestations internes au camping :
        </Subtitle>
        <Text>
          Sur option, le Partenaire a la possibilité de proposer aux utilisateurs de la plateforme
          izicamp.fr des services et prestations internes au camping (location vélo, linge de lit,
          etc.). En contrepartie de la vente réalisée par le Prestataire desdits services et
          prestations, ce dernier percevra une commission qui s’élève à 10% HT du montant TTC pour
          chaque service et prestation vendus.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="5. Conditions d'annulation" />
        </ListItem>
        <Subtitle>Couverture par l’assurance annulation : </Subtitle>
        <Text>
          izicamp.fr offre gratuitement et systématiquement pour chaque séjour vendu une assurance
          annulation au client. Les conditions générales de cette assurance annulation sont
          disponibles sur simple demande.
        </Text>
        <br></br>
        <Text>
          En cas d’annulation de la réservation par le client couverte par l’assurance annulation,
          le montant du séjour sera versé au partenaire, déduction faite de la commission du
          Prestataire définie à l’article 4.1. Dès remboursement du client par l’assurance
          l’hébergement sera réputé à nouveau disponible à la location.
        </Text>
        <br></br>
        <Subtitle>Annulation hors assurance :</Subtitle>
        <Text>
          En cas d’annulation de la réservation par le client non couverte par l’assurance
          annulation, le Partenaire touchera une somme conformément au barème indiqué à l’article
          5.2. des conditions générales du présent accord de partenariat.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="6. Versement des soldes locatifs" />
        </ListItem>
        <Text>
          Le Partenaire est libre de choisir à quel moment les soldes locatifs lui seront versés :
        </Text>
        <BackgroundInfo elevation={0}>
          <Controller
            as={
              <RadioGroup aria-label="immediatePayout" name="immediatePayout">
                <FormControlLabel
                  value="true"
                  control={<Radio />}
                  label="Avant le démarrage effectif du séjour du Client, au fur et à mesure de
                  l'encaissement (voir conditions générales §2.3.3)."
                />
                <FormControlLabel
                  value="false"
                  control={<Radio />}
                  label="Après le démarrage effectif du séjour du Client."
                />
              </RadioGroup>
            }
            name="immediatePayout"
            control={control}
          />
        </BackgroundInfo>
        <br></br>
        <Text>
          Les conditions de versement sont détaillées à l’article 4 des conditions générales du
          présent accord de partenariat. Par défaut, en cas d’absence de choix au présent accord, le
          versement s’effectuera après le démarrage des séjours.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="7. Autorisation d'accès" />
        </ListItem>
        <Text>
          Le Partenaire autorise le Prestataire et ses sous-traitants à accéder au camping et aux
          hébergements concernés par le contrat dans tout but commercial, opérationnel ou de
          relation client.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="8. Utilisation des visuels" />
        </ListItem>
        <Text>
          Le Partenaire autorise le Prestataire à utiliser images, photographies et vidéos ainsi que
          tout autre élément nécessaire à la visibilité et à la commercialisation de son offre.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="9. Campagnes de communication digitales et classiques" />
        </ListItem>
        <Text>
          Le Prestataire engage à ses frais des campagnes de communication multicanaux et d’achat de
          mots-clés sur Internet pour booster la visibilité de ses partenaires.
          <br></br>
          Le Partenaire déclare autoriser le Prestataire à mentionner son nom commercial dans des
          annonces :
        </Text>
        <BackgroundInfo elevation={0}>
          <Controller
            as={
              <RadioGroup aria-label="advertisingCampaign" name="advertisingCampaign">
                <FormControlLabel value="true" control={<Radio />} label="Oui" />
                <FormControlLabel value="false" control={<Radio />} label="Non" />
              </RadioGroup>
            }
            name="advertisingCampaign"
            control={control}
          />
        </BackgroundInfo>
        <br></br>
        <Text>
          Le Partenaire pourra à tout moment modifier son souhait en informant le Prestataire par
          simple e-mail. Il est spécifié que le Prestataire s’affranchira de cette demande
          d’autorisation s’il s’avérait, pendant l’exécution de ce contrat, que le Partenaire
          autorisait ou ne faisait pas respecter son refus de cette pratique par les concurrents du
          Partenaire.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="10. Mode de commercialisation dit « Free Sale »" />
        </ListItem>
        <Text>
          Le Partenaire confie au Prestataire tout ou partie de son parc pour la commercialisation
          de séjours en hébergements locatifs en France et à l’international. Il s’engage à donner
          accès à des disponibilités sur l’ensemble de la période d’ouverture de son établissement.
        </Text>
        <br></br>
        <Text>
          La collaboration nécessite que les disponibilités du Partenaire soient accessibles via :
          (cocher la case correspondante)
        </Text>
        <br></br>
        <Text>
          <strong>Via une connexion passerelle :</strong> Les tarifs et disponibilités seront
          directement issus du paramétrage effectué par le Partenaire, engageant ainsi sa seule et
          complète responsabilité.
        </Text>
        <BackgroundInfo elevation={0}>
          <FormControl component="fieldset">
            <Controller
              as={
                <RadioGroup aria-label="passerelle" name="interface">
                  <FormControlLabel value="nothing" control={<Radio />} label="Aucun" />
                  <FormControlLabel value="sequoiasoft" control={<Radio />} label="Sequoiasoft" />
                  <FormControlLabel value="inaxel" control={<Radio />} label="Inaxel" />
                  <FormControlLabel
                    value="secureholiday"
                    control={<Radio />}
                    label="Secureholiday"
                  />
                  <FormControlLabel value="other" control={<Radio />} label="Autre" />
                </RadioGroup>
              }
              name="interface"
              control={control}
            />
          </FormControl>
        </BackgroundInfo>
        <br></br>
        <Text>
          <strong>Hors connexion passerelle :</strong> Dans le cas où la connexion aux tarifs et
          disponibilités du Partenaire ne se fait pas via une connexion passerelle prévue à
          l’article précédent, leur paramétrage sera effectué par le Partenaire via l’Extranet du
          Prestataire par saisie manuelle ou par acceptation des grilles de tarifs proposées par la
          plateforme engageant ainsi la seule responsabilité du Partenaire. Le Partenaire pourra
          lui-même actualiser ses stocks (stop vente) depuis cet extranet (prise en compte
          instantanée), ou informer le Prestataire par e-mail, qui les actualisera pour son compte,
          et dans un délai de 2 heures (7 jours sur 7 de 10 H à 22h). Pendant ce délai, l’allotement
          sera considéré comme disponible.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="11. Fichier Client" />
        </ListItem>
        <Text>
          Le Prestataire vend les séjours pour le compte du Partenaire. Dès lors le Prestataire
          transmet au Partenaire de manière transparente l’ensemble des informations concernant le
          Client, ce dernier étant considéré faire partie intégrante du fichier client du
          Prestataire et du Partenaire.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="12. Execution et mise en application" />
        </ListItem>
        <Text>
          Le présent Accord ne prendra effet qu&apos;après validation du dossier et confirmation par
          le Prestataire.
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="13. Date de l'accord" />
        </ListItem>
        <Text>
          Date de signature de l&apos;accord : <strong>{date()}</strong>
        </Text>
        <Divider />
        <ListItem>
          <ListItemText primary="14. Conditions générales" />
        </ListItem>
        <Text>Le présent Accord est soumis aux conditions générales de Prestation.</Text>
      </List>
    </>
  );
};

export default Terms;
