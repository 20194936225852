import styled from 'styled-components';

export const Container = styled.footer<{ whiteFont: boolean }>`
  display: flex;
  padding: 0.9375rem;
`;

export const Title = styled.h2`
  display: block;
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  margin: 16px 0 30px 0;
`;

export const Item = styled.a`
  display: block;
  justify-content: space-between;
  color: r#38404d;
  margin-top: 15px;
  &:hover {
    color: #ff4c1f;
    text-decoration: underline;
  }
`;

export const PhoneSection = styled.div`
  display: flex;
  align-items: end;
  margin: 30px 0 0 0;
  border-top: 1px solid #908e8e4a;
`;
export const PhoneItem = styled.a`
  display: flex;
  flex-direction: column;
  margin: 15px;
`;

export const Number = styled.div`
  color: #212529;
  font-family: Poppins;
  line-height: 20px;
`;

export const Copyright = styled.div`
  text-transform: none;
  display: block;
  justify-content: center;
`;

export const Flex = styled.div`
  text-align: center;
  display: block;
  justify-content: center;
`;
