// Apollo graphql
import { ApolloProvider } from '@apollo/client';
import Footer from 'components/Footer';
import HeaderForm from 'components/Header/HeaderForm';
// core components
import Theme from 'components/Theme';
import React, { FunctionComponent } from 'react';

// Lib
import client from '../../graphql';
// Sections
import BaseFormSection from './Sections/BaseFormSection';
// Styles
import { Container, Main, Title } from './style';

const ParkApplicationForm: FunctionComponent = () => {
  return (
    <Theme>
      <HeaderForm
        fixed
        changeStyleOnScroll={{
          shadowHeight: 100,
          buttonHeight: 0,
        }}
      />
      <ApolloProvider client={client}>
        <Main>
          <Container>
            <Title>Déclarez vous proprietaire de votre camping</Title>
            <BaseFormSection />
          </Container>
        </Main>
        <Footer />
      </ApolloProvider>
    </Theme>
  );
};

export default ParkApplicationForm;
