import React, { FunctionComponent } from 'react';

// Styles
import { Container } from './style';

const PageContainer: FunctionComponent = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default PageContainer;
