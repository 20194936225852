import React, { FunctionComponent, ReactNode } from 'react';

// Styles
import { Container } from './style';

interface Props {
  children: ReactNode;
}

const Bubble: FunctionComponent<Props> = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

export default Bubble;
